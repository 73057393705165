import { computed, action, observable } from 'mobx';
import { History } from 'history';
import { memoryHistory, browserHistory } from 'utils/history';

import config from 'config';
import ApiLayer from 'services/APIServices/ApiLayer';
import { AppMode } from 'constants/AppMode';
import { getExperimentSettings } from 'services/GrowthBookServices';
import { removeParamFromURL, renderingInIFrame } from 'utils/utility';
import StoreBase from '../StoreBase';
import { DriverAppStore } from '../DriverAppStore';

export default class ConfigStore extends StoreBase {
  rootStore: DriverAppStore;
  history: History;
  @observable pageTitle: string = config.partner.name || '';
  @observable initialLoadProgress: number = 0;
  @observable offerSources = [];
  @observable experimentSettings = {};

  constructor(rootStore: DriverAppStore) {
    super();
    this.rootStore = rootStore;

    // manage history object within myself
    this.history = browserHistory;

    // use memory router for geotab
    if (this.isGeotab) {
      this.history = memoryHistory;
    }
  }

  @computed get isBigroad() {
    return config.appMode === AppMode.BIGROAD;
  }

  @computed get isGeotab() {
    return config.appMode === AppMode.GEOTAB;
  }

  @computed get isEldLoads() {
    return config.appMode === AppMode.ELDLOADS;
  }

  @computed get isFleetTrack() {
    return config.appMode === AppMode.FLEETTRACK;
  }

  @computed get isFreightMate() {
    return config.appMode === AppMode.FREIGHTMATE;
  }

  @computed get isSwitchboard() {
    return config.appMode === AppMode.SWITCHBOARD;
  }

  @computed get isEzloadz() {
    return config.appMode === AppMode.EZLOADZ;
  }

  @computed get isFleetPulse() {
    return config.appMode === AppMode.FLEETPULSE;
  }

  @computed get isPhillips() {
    return config.appMode === AppMode.PHILLIPS;
  }

  @computed get isSSO() {
    return config.ssoUrl ? true : false;
  }

  @computed get customLoadingGif() {
    return this.isFleetPulse ? true : false;
  }

  @computed get indexRoute() {
    const { isSSO } = this.rootStore.configStore;
    const { showLandingPage, loggedIn, commonCompletedOnboardingCheck } = this.rootStore.userStore;
    /**
       * TEMPORARILY DISABLING LP WHILE ONBOARDING SSO
       * UNCOMMENT IF WE NEED TO REDIRECT TO LP WHILE ONBOARDING
       if (showLandingPage) {
         return '/driver/landing';
       }
       */
    if (!commonCompletedOnboardingCheck) {
      if (
        window.location.search?.includes('action') ||
        window.location.search?.includes('requestId')
      ) {
        return `/driver/onboarding?internalRedirect=${window.location.pathname}${window.location.search}`;
      } else {
        return '/driver/onboarding';
      }
    }
    // Redirect back to the desired page after completing onboarding
    else if (window.location.href.includes('internalRedirect')) {
      if (window.location.href.includes('driver/load')) {
        return window.location.search.split('internalRedirect=')[1];
      } else {
        removeParamFromURL('internalRedirect');
      }
    }
  }

  /**
   * Logout button is currently hidden only for Ez if app is rendered in
   * an iframe and shown if its opened in the browser
   */
  @computed get hideLogoutButton() {
    return (this.isEzloadz && renderingInIFrame()) || this.isFleetPulse;
  }

  @computed get isTrailerSolution() {
    return config.trailerSolution;
  }

  @action.bound
  async setOfferSources(offerSources) {
    this.offerSources = offerSources;
  }

  @action.bound
  getOfferSources = () => {
    ApiLayer.getOfferSources().then((data) => {
      this.setOfferSources(data);
    });
  };

  @action.bound
  setDefaultPageTitle = () => {
    this.pageTitle = config.partner.name;
  };

  @action.bound
  setPageTitle = (pageTitle: string) => {
    if (pageTitle) {
      this.pageTitle = pageTitle;
    } else {
      this.setDefaultPageTitle();
    }
  };

  @action.bound
  setInitialLoadProgress = (initialLoadProgress: number) => {
    this.initialLoadProgress = initialLoadProgress;
  };

  @action.bound
  setExperimentProperties = async () => {
    try {
      const { overrides } = await getExperimentSettings();

      // { 'a': {}, 'b': {} }
      this.experimentSettings = overrides;
    } catch (error) {
      console.error(error);
    }
  };
}
