import bigroad from './bigroad';
import geotab from './geotab';
import fleettrack from './fleettrack';
import eldloads from './eldloads';
import freightmate from './freightmate';
import switchboard from './switchboard';
import ezloadz from './ezloadz';
import fleetpulse from './fleetpulse';
import phillips from './phillips';

const appConfig = {
  bigroad,
  geotab,
  fleettrack,
  eldloads,
  freightmate,
  switchboard,
  ezloadz,
  fleetpulse,
  phillips,
};

export default {
  appBranch: process.env.appBranch || undefined,
  netlifySiteName: process.env.netlifySiteName || undefined,
  netlifyApiId: process.env.netlifyApiId || undefined,
  netlifyToken: process.env.netlifyToken || undefined,
  ...appConfig[process.env.appMode || 'bigroad'],
};
