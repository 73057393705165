export enum AppMode {
  ELDLOADS = 'eldloads',
  BIGROAD = 'bigroad',
  GEOTAB = 'geotab',
  FLEETTRACK = 'fleettrack',
  FREIGHTMATE = 'freightmate',
  SWITCHBOARD = 'switchboard',
  EZLOADZ = 'ezloadz',
  FLEETPULSE = 'fleetpulse',
  PHILLIPS = 'phillips',
}
