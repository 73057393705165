import config from 'config';
import { AppMode } from 'constants/AppMode';
import { EMAIL_NOT_FOUND_ERROR, INVALID_PASSWORD } from 'constants/Messages';
import { MIXPANEL_EVENTS, MIXPANEL_KEYS, PAGE_SOURCE } from 'constants/Mixpanel';
import firebase, { Unsubscribe } from 'firebase/app';
import 'firebase/auth';
import { History } from 'history';
import { action, computed, observable } from 'mobx';
import queryStringParser from 'query-string';
import ApiLayer from 'services/APIServices/ApiLayer';
import PostGeotabAuthtoken from 'services/APIServices/Auth/PostGeotabAuthtoken';
import PostCreateServiceAccount from 'services/APIServices/Geotab/PostGeotabCreateServiceAccount';
import {
  mixpanelRegister,
  mixpanelSetUser,
  mixpanelTrack,
  mixpanelUpdateUser,
} from 'services/FOMixpanel';
import { growthbook } from 'services/GrowthBookServices';
import bigroadTheme from 'theme/bigroad';
import eldloadsTheme from 'theme/eldloads';
import ezloadzTheme from 'theme/ezloadz';
import fleettrackTheme from 'theme/fleettrack';
import freightmateTheme from 'theme/freightmate';
import geotabTheme from 'theme/geotab';
import switchboardTheme from 'theme/switchboard';
import fleetpulseTheme from 'theme/fleetpulse';
import { parseQueryParamsToSingleOutput } from 'utils/utility';
import { DriverAppStore } from '../DriverAppStore';
import StoreBase from '../StoreBase';
import phillipsTheme from 'theme/phillips';

export default class PartnerStore extends StoreBase {
  rootStore: DriverAppStore;
  authListener: Unsubscribe | null = null;
  @observable geotabServer = window.location.hostname;
  @observable geotabUser = null;
  @observable geotabSession = null;
  @observable activeUserId = null;
  @observable history;

  constructor(rootStore: DriverAppStore) {
    super();
    this.rootStore = rootStore;
    this.history = this.rootStore.configStore.history;
  }

  @computed get theme() {
    if (this.rootStore.configStore.isEldLoads) {
      return eldloadsTheme;
    }
    if (this.rootStore.configStore.isFleetTrack) {
      return fleettrackTheme;
    }
    if (this.rootStore.configStore.isBigroad) {
      return bigroadTheme;
    }
    if (this.rootStore.configStore.isGeotab) {
      return geotabTheme;
    }
    if (this.rootStore.configStore.isFreightMate) {
      return freightmateTheme;
    }
    if (this.rootStore.configStore.isSwitchboard) {
      return switchboardTheme;
    }
    if (this.rootStore.configStore.isEzloadz) {
      return ezloadzTheme;
    }
    if (this.rootStore.configStore.isFleetPulse) {
      return fleetpulseTheme;
    }
    if (this.rootStore.configStore.isPhillips) {
      return phillipsTheme;
    }
    return bigroadTheme;
  }

  @computed get isGeotabAdmin() {
    return (
      this.geotabUser?.securityGroups?.length > 0 &&
      this.geotabUser?.securityGroups[0]?.id === 'GroupEverythingSecurityId'
    );
  }

  storeGeotabUser = (geotabUser) => {
    this.geotabUser = geotabUser;
  };

  storeGeotabSession = (geotabSession) => {
    this.geotabSession = geotabSession;
  };

  @action.bound
  setActiveUserId = (userId) => {
    this.activeUserId = userId;
  };

  @action.bound
  createServiceAccount = async () => {
    if (this.isGeotabAdmin && this.geotabSession) {
      try {
        const response = await PostCreateServiceAccount(
          this.geotabServer,
          this.geotabSession.database,
          this.geotabSession.userName,
          this.geotabSession.sessionId,
        );
        this.rootStore.userStore.setFleet(response);
      } catch (e) {
        this.rootStore.snackbarStore.enqueueSnackbarStore('Error creating service account', {
          variant: 'error',
        });
        throw e;
      }
    }
  };

  authErrorHandler = () => {
    const {
      configStore: { isGeotab, isFleetTrack, isFleetPulse },
    } = this.rootStore;
    if (isFleetTrack) {
      this.redirectToLoginPage(this.history);
    } else if (isGeotab) {
      this.checkGeotabLogin(this.history);
    } else if (isFleetPulse) {
      this.rootStore.snackbarStore.enqueueSnackbarStore('Error trying to log in', {
        variant: 'error',
      });
    } else {
      this.redirectToSSOUrl();
    }
  };

  /**
   * Since auth is the first API call thats made when the app loads
   * we add a handler to distinguish between token validation errors
   * vs API/DB errors and redirect based on error type
   * @param error - API response data.msg
   // TODO: Needs to be updated to check error code once BE changes are implemented
   */
  apiErrorHandler = (error) => {
    const statusCode = error?.response?.status;
    if (statusCode === 503) {
      this.redirectToMaintenancePage();
    } else if (statusCode === 400) {
      this.removeSSOQueryParams();
      this.redirectToSSOUrl();
    } else {
      this.removeSSOQueryParams();
      this.redirectToErrorPage();
    }
  };

  /**
   * If database is down, for maintenance etc
   * we redirect to a maintenance page
   */
  redirectToMaintenancePage = () => {
    this.history.push('/maintenance');
  };

  /**
   * If setup APIs fail (eg. /me, /relationships, etc)
   * we redirect to an error page with a refresh button
   * that redirects back to the index route
   */
  redirectToErrorPage = () => {
    this.history.push('/error');
  };

  // Authentication Stage 1: Check the bigroad token
  checkSSOLogin = (history: History) => {
    const { location } = window;
    const {
      configStore: {
        isBigroad,
        isEldLoads,
        isFreightMate,
        isSwitchboard,
        isEzloadz,
        isFleetPulse,
        isPhillips,
      },
      userStore: {
        checkingAuth,
        checkBigRoadToken,
        checkEldLoadsToken,
        checkFreightMateToken,
        checkSwitchboardToken,
        checkEZLoadzToken,
        checkFleetPulseToken,
        checkPhillipsToken,
      },
    } = this.rootStore;

    const parsedQueryParams = queryStringParser.parse(location.search);

    // Temporary solution to keep query params saved for pre-populated search after redirecting to the SSO url
    if (parsedQueryParams.PU) {
      localStorage.setItem('prepopulatedSearchParams', JSON.stringify(parsedQueryParams));
    }

    if (parsedQueryParams.activeUserId) {
      this.setActiveUserId(parseQueryParamsToSingleOutput(parsedQueryParams.activeUserId));
    }

    // If activeUserId exists, bypass login and authenticate using activeUserId
    if (this.activeUserId) {
      const formData = {
        userId: this.activeUserId,
      };

      if (checkingAuth) {
        return;
      }
      if (isBigroad) {
        checkBigRoadToken(formData)
          .then((data) => this.handleSSOToken(data, data.user.email, data.custom_token, history))
          .catch(this.apiErrorHandler);
      } else if (isEldLoads) {
        checkEldLoadsToken(formData)
          .then((data) => this.handleSSOToken(data, data.user.email, data.custom_token, history))
          .catch(this.apiErrorHandler);
      } else if (isFreightMate) {
        checkFreightMateToken(formData)
          .then((data) => this.handleSSOToken(data, data.user.email, data.custom_token, history))
          .catch(this.apiErrorHandler);
      } else if (isSwitchboard) {
        checkSwitchboardToken(formData)
          .then((data) => this.handleSSOToken(data, data.user.email, data.custom_token, history))
          .catch(this.apiErrorHandler);
      } else if (isEzloadz) {
        checkEZLoadzToken(formData)
          .then((data) => this.handleSSOToken(data, data.user.email, data.custom_token, history))
          .catch(this.apiErrorHandler);
      } else if (isFleetPulse) {
        checkFleetPulseToken(formData)
          .then((data) => this.handleSSOToken(data, data.user.email, data.custom_token, history))
          .catch(this.apiErrorHandler);
      } else if (isPhillips) {
        checkFleetPulseToken(formData)
          .then((data) => this.handleSSOToken(data, data.user.email, data.custom_token, history))
          .catch(this.apiErrorHandler);
      }
    } else {
      /**
       * SSO Login with BigRoad, ELD Loads, One View
       * If there is an auth token present in the url then we need to reauthenticate with
       * our backend to ensure that the token is valid. This is regardless of whether the user is
       * currently logged in or not.
       * When the user is redirected from bigroad's SSO login it will always have a '?authToken=..'.
       */

      const email = parseQueryParamsToSingleOutput(parsedQueryParams.email);
      const userId = parseQueryParamsToSingleOutput(parsedQueryParams.userId);
      /**
       * Updated token handling to handle for inludes instead of directly accessing the key
       * since we noticed some partners (ex. Ezlogz) appends ?token to the redirect url without
       * checking if additional params exist in the redirectUrl. This only happens in the case of
       * redirection via email notifications like hubspot. This can lead to the token/authToken param like
       * ??token= or ?&?token= which would FE if we try to access .token or .authToken
       */
      let token: string | null = null;
      const tokenKey = Object.keys(parsedQueryParams).filter((i) => {
        return i.includes('token') || i.includes('authToken');
      });
      if (tokenKey.length > 0) {
        // Some ELDs send authToken whereas others send token so this array should always have length = 1
        token = parseQueryParamsToSingleOutput(parsedQueryParams[tokenKey[0]]);
      }

      let formData = {};

      if (this.checkLoginParams(email, token, userId)) {
        if (checkingAuth) {
          return;
        }
        if (isBigroad) {
          formData = {
            email,
            token,
          };
          checkBigRoadToken(formData)
            .then((data) => this.handleSSOToken(data, email, token, history))
            .catch(this.apiErrorHandler);
        } else if (isEldLoads) {
          formData = {
            token,
          };
          checkEldLoadsToken(formData)
            .then((data) => this.handleSSOToken(data, email, token, history))
            .catch(this.apiErrorHandler);
        } else if (isFreightMate) {
          formData = {
            token,
            fmUserId: userId,
          };
          checkFreightMateToken(formData)
            .then((data) => this.handleSSOToken(data, email, token, history))
            .catch(this.apiErrorHandler);
        } else if (isSwitchboard) {
          formData = {
            email,
            token,
          };
          checkSwitchboardToken(formData)
            .then((data) => this.handleSSOToken(data, email, token, history))
            .catch(this.apiErrorHandler);
        } else if (isEzloadz) {
          formData = {
            token,
          };
          checkEZLoadzToken(formData)
            .then((data) => this.handleSSOToken(data, email, token, history))
            .catch(this.apiErrorHandler);
        } else if (isFleetPulse) {
          // Temporarily use BRF auth flow until we confirm FleetPulse auth flow
          formData = {
            email,
            token,
          };
          checkFleetPulseToken(formData)
            .then((data) => this.handleSSOToken(data, email, token, history))
            .catch(this.apiErrorHandler);
        } else if (isFleetPulse) {
          // TODO: Temporarily use FleetPulse auth flow for demo
          formData = {
            email,
            token,
          };
          checkFleetPulseToken(formData)
            .then((data) => this.handleSSOToken(data, email, token, history))
            .catch(this.apiErrorHandler);
        }
      } else {
        this.setupFirebaseAuthListenerSSO(history);
      }
    }
  };

  checkLoginParams(email: string, token: string, userId: string) {
    const {
      configStore: { isFreightMate, isEldLoads, isEzloadz },
    } = this.rootStore;

    if (isFreightMate) {
      return token && userId;
    }

    // Eld Loads & Ezloadz gives us just the token in the url
    if (isEldLoads || isEzloadz) {
      return token;
    }

    return email && token;
  }

  // Authentication Stage 2: Process the checked bigroad token
  handleSSOToken = (data, email: string, authToken: string, history: History) => {
    const {
      userStore: { preserveAuthQueryParams },
    } = this.rootStore;

    preserveAuthQueryParams(email, authToken);

    if (data && data.custom_token) {
      // We use firebase's custom token sign in as it takes care of maintaining the user's login
      // state using browser cookies. It also enables access to the currentUser object to
      // any component in the app.
      firebase
        .auth()
        .signInWithCustomToken(data.custom_token)
        .then(() => this.setupFirebaseAuthListenerSSO(history))
        .catch(() => this.authErrorHandler());
    } else {
      this.authErrorHandler();
    }
  };

  // Authentication Stage 3: Setup the listener
  // Should be set up after auth sign in is called
  setupFirebaseAuthListenerSSO = (history: History) => {
    if (!this.authListener) {
      this.authListener = firebase.auth().onAuthStateChanged(
        (user) => {
          this.removeSSOQueryParams();
          if (user) {
            ApiLayer.me()
              .then(async (foUser) => {
                if (foUser && foUser.user) {
                  this.setupUser(foUser);
                } else {
                  this.redirectToErrorPage();
                }
              })
              .catch(() => this.redirectToErrorPage());
          } else {
            this.authErrorHandler();
          }
          // This is to handle any errors in Firebase Logins.
        },
        () => this.authErrorHandler(),
      );
    }
  };

  // Authentication Stage 4: Clean url pathname
  removeSSOQueryParams = () => {
    // Auth params need to be removed from the url. If they exist past this point
    // then the authentication flow may get triggered again as it starts by checking
    // for these params in the first place. There has been a long running bug described as:
    // when the user logs in they see the Green Flash Screen in an endless loop.
    // Removing the params completely should avoid the above bug.
    const {
      configStore: { isFreightMate, isEldLoads, isEzloadz },
    } = this.rootStore;
    const {
      location: { search, pathname },
    } = window;
    const parsedQueryParams = queryStringParser.parse(search);
    let cleanedSearch = '';

    // Remove params used for authentication
    Object.keys(parsedQueryParams).forEach((k) => {
      if (k === 'activeUserId') {
        this.setActiveUserId(null);
        return;
      }

      if (isFreightMate || isEzloadz) {
        if (k.includes('token') || k === 'userId') {
          return;
        }
      }

      if (isEldLoads) {
        if (k.includes('token') || k === 'partnerId') {
          return;
        }
      }

      if (k.includes('authToken') || k === 'email') {
        return;
      }
      if (cleanedSearch === '') {
        cleanedSearch = '?';
      }
      cleanedSearch += `&${k}=${parsedQueryParams[k]}`;
    });

    // Pull default app-wide configured history and push
    this.history.push({
      pathname,
      search: cleanedSearch,
    });
  };

  // Authentication Stage 5: Setup the user.
  setupUser = async (foUser) => {
    const {
      userStore,
      configStore: { isFleetTrack, getOfferSources, setExperimentProperties },
    } = this.rootStore;
    userStore.setLogin(true);
    userStore.setFOUser(foUser.user);
    mixpanelSetUser(foUser.user, userStore?.userTypeString);

    this.updateUserLocation(foUser.user.truck);
    try {
      /* Commented out the call to get the experiment list
       for now to avoid unnecessary triggers */
      // await setExperimentProperties();
      const { id, companyName } = foUser.user;
      growthbook.setAttributes({
        id,
        loggedIn: true,
        company: companyName,
        browser: navigator.userAgent,
        url: location.pathname,
      });
    } catch (error) {
      console.error(error);
    }
    this.removeSSOQueryParams();
    getOfferSources();
    if (userStore.dispatcher) {
      try {
        userStore.getRelatedDispatcherDrivers();
      } catch (error) {
        this.redirectToErrorPage();
      }
    }
    /**
     Needed for driver-dispatcher permissions
     * &
     Used to get dispatchers info for dispatcher settings
     */
    userStore.getRelatedDispatchers();
  };

  redirectToSSOUrl = () => {
    const {
      configStore: { isFreightMate, isPhillips },
    } = this.rootStore;

    const {
      location: { search, origin, pathname },
    } = window;

    // Set the redirect to the current url. Preserve the search query params as they may contain utm tags.
    let ssoQueryParams = `?redirect=${origin}${pathname}${search}`;

    // FreightMate already has the redirect in the base url
    if (isFreightMate) ssoQueryParams = encodeURIComponent(`${origin}${pathname}${search}`);

    // If the current url has src=br and email='' then append email as
    // a query param. This happens for when users are redirected (after clicking a button)
    // from big road's mobile application to this application.
    const parsedQueryParams = queryStringParser.parse(search);

    const email = parseQueryParamsToSingleOutput(parsedQueryParams.email);
    const src = parseQueryParamsToSingleOutput(parsedQueryParams.src);
    const action = parseQueryParamsToSingleOutput(parsedQueryParams?.action);
    const requestId = parseQueryParamsToSingleOutput(parsedQueryParams?.requestId);

    localStorage.removeItem('action');
    localStorage.removeItem('requestId');
    localStorage.setItem('action', JSON.stringify(action));
    localStorage.setItem('requestId', JSON.stringify(requestId));

    if (email && src === 'br') {
      ssoQueryParams = `${ssoQueryParams}&email=${email}`;
    }

    if (isPhillips) {
      this.history.push(`/driver/login`);
    } else {
      // TODO - Remove conditions when Phillips is handled
      window.location.href = `${config.ssoUrl}${ssoQueryParams}`;
    }
  };

  checkGeotabLogin = async () => {
    const {
      userStore: { loggedIn },
    } = this.rootStore;
    if (!loggedIn) {
      const { geotab } = window;
      // May need this in the future if we use geotab addin api. But if we don't need it for authorization, then this should be removed
      if (geotab) {
        geotab.addin.fleetops = this.geotabAddinfunction(this.history);
      } else {
        // This branch of the condition is used for local testing. You can get the token used
        // in the line below from the geotab calls when you log into geotab web app.

        const localUser = 'liselle@fleetops.ai';

        // get auth from geotab
        const geotabAuthResponse = await PostGeotabAuthtoken(
          'mypreview2.geotab.com',
          'fleetops',
          localUser,
          'E9DHE_R4SMuxq93bczvTog',
        );
        await this.handleGeotabToken(geotabAuthResponse.custom_token, { name: localUser });
      }
    }
  };

  handleGeotabToken = async (customToken: string, geotabUser) => {
    const {
      userStore: { preserveAuthQueryParams },
    } = this.rootStore;

    preserveAuthQueryParams(geotabUser.name, customToken);

    // We use firebase's custom token sign in as it takes care of maintaining the user's login
    // state using browser cookies. It also enables access to the currentUser object to
    // any component in the app.
    try {
      await firebase.auth().signInWithCustomToken(customToken);
      this.setupFirebaseAuthListenerGeotab(geotabUser);
    } catch {
      this.redirectToSSOUrl();
    }
  };

  setupFirebaseAuthListenerGeotab = (geotabUser) => {
    if (!this.authListener) {
      this.authListener = firebase.auth().onAuthStateChanged(
        (user) => {
          if (user) {
            ApiLayer.me()
              .then(async (foUser) => {
                if (foUser && foUser.user) {
                  this.storeGeotabUser(geotabUser);
                  this.setupUser(foUser);
                } else {
                  this.redirectToErrorPage();
                }
              })
              .catch(() => this.redirectToErrorPage());
          } else {
            console.log('auth state change error');
          }
          // This is to handle any errors in Firebase Logins.
        },
        () => console.log('firebase error'),
      );
    }
  };

  geotabAddinfunction = (history) => {
    let api;
    const {
      handleGeotabToken,
      storeGeotabSession,
      geotabServer,
      rootStore: {
        userStore: { setFleet },
      },
    } = this;

    function getUserConfiguration() {
      // The api object exposes a method we can call to get the current user identity. This is useful for
      // determining user context, such as regional settings, language preference and name. Use the api
      // to retrieve the currently logged on user object.
      api.getSession((session) => {
        const currentUser = session.userName;

        api.call(
          'Get',
          {
            typeName: 'User',
            search: {
              name: currentUser,
            },
          },
          async (result) => {
            if (result.length === 0) {
              throw new Error('Unable to find currently logged on user.');
            }

            const user = result[0];

            try {
              const geotabAuthResponse = await PostGeotabAuthtoken(
                geotabServer,
                session.database,
                session.userName,
                session.sessionId,
              );
              await handleGeotabToken(geotabAuthResponse.custom_token, user);
              storeGeotabSession(session);
              setFleet(geotabAuthResponse.fleet);
            } catch (e) {
              console.log(e);
            }
          },
          (error) => {
            if (error.response.status && error.response.status === 503) {
              history.push('/maintenance');
            } else {
              throw new Error(`Error while trying to load currently logged on user. ${error}`);
            }
          },
        );
      });
    }

    return {
      /**
       * initialize() is called only once when the Add-In is first loaded. Use this function to initialize the
       * Add-In's state such as default values or make API requests (MyGeotab or external) to ensure interface
       * is ready for the user.
       * @param {object} freshApi - The GeotabApi object for making calls to MyGeotab.
       * @param {object} freshState - The page state object allows access to URL, page navigation and global group filter.
       * @param {function} initializeCallback - Call this when your initialize route is complete. Since your initialize routine
       *        might be doing asynchronous operations, you must call this method when the Add-In is ready
       *        for display to the user.
       */
      initialize(freshApi, freshState, initializeCallback) {
        api = freshApi;
        state = freshState;

        initializeCallback();
      },

      /**
       * focus() is called whenever the Add-In receives focus.
       *
       * The first time the user clicks on the Add-In menu, initialize() will be called and when completed, focus().
       * focus() will be called again when the Add-In is revisited. Note that focus() will also be called whenever
       * the global state of the MyGeotab application changes, for example, if the user changes the global group
       * filter in the UI.
       *
       * @param {object} freshApi - The GeotabApi object for making calls to MyGeotab.
       * @param {object} freshState - The page state object allows access to URL, page navigation and global group filter.
       */
      focus(freshApi, freshState) {
        api = freshApi;
        state = freshState;
        getUserConfiguration();
      },

      /**
       * blur() is called whenever the user navigates away from the Add-In.
       *
       * Use this function to save the page state or commit changes to a data store or release memory.
       *
       * @param {object} freshApi - The GeotabApi object for making calls to MyGeotab.
       * @param {object} freshState - The page state object allows access to URL, page navigation and global group filter.
       */
      blur() {},
    };
  };

  checkFleetTrackLogin = async () => {
    const parsedQueryParams = queryStringParser.parse(location.search);
    if (parsedQueryParams.activeUserId) {
      this.setActiveUserId(parseQueryParamsToSingleOutput(parsedQueryParams.activeUserId));
    }
    if (this.activeUserId) {
      this.handleFleetTrackLogin(null, null, null, null);
    } else {
      this.setupFirebaseAuthListenerFleetTrack();
    }
  };

  handleFleetTrackLogin = async (
    email: string,
    password: string,
    emailFormField,
    passwordFormField,
  ) => {
    const {
      userStore: { preserveAuthQueryParams },
    } = this.rootStore;
    let formData = { email, password };
    if (this.activeUserId) {
      formData = {
        userId: this.activeUserId,
      };
    }

    ApiLayer.fleetTrackLogin(formData)
      .then((data) => {
        const { custom_token } = data;
        if (custom_token) {
          preserveAuthQueryParams(email || data.user.email, custom_token);
          firebase
            .auth()
            .signInWithCustomToken(custom_token)
            .then(() => {
              mixpanelTrack(MIXPANEL_EVENTS.FTF_USER_LOGGED_IN, {
                [MIXPANEL_KEYS.PAGE_SOURCE]:
                  this.history.location.pathname == '/driver/login'
                    ? PAGE_SOURCE.LOGIN
                    : PAGE_SOURCE.EXISTING_EMAIL,
              });
              this.setupFirebaseAuthListenerFleetTrack();
              this.removeRegisterQueryParams();
              if (!this.activeUserId) {
                this.history.push('/');
              }
            })
            .catch(() => this.redirectToLoginPage(this.history));
        } else {
          this.redirectToLoginPage(this.history);
        }
      })
      .catch((error) => {
        const { data } = error;
        if (data.msg.includes(EMAIL_NOT_FOUND_ERROR.error)) {
          emailFormField.invalidate(EMAIL_NOT_FOUND_ERROR.message);
        } else if (data.msg.includes(INVALID_PASSWORD.error)) {
          passwordFormField.invalidate(INVALID_PASSWORD.message);
        } else if (error.response.status === 503) {
          history.push('/maintenance');
        } else {
          this.rootStore.snackbarStore.enqueueSnackbarStore('Error logging in user.', {
            variant: 'error',
          });
        }
      });
  };

  handleFleetTrackRegister = async (
    email: string,
    password: string,
    accountId: string,
    dotNumber: string,
  ) => {
    const {
      userStore: { preserveAuthQueryParams },
    } = this.rootStore;
    ApiLayer.fleetTrackRegister(email, password, accountId, dotNumber)
      .then((data) => {
        const { custom_token, user } = data;
        if (custom_token) {
          preserveAuthQueryParams(email, custom_token);
          firebase
            .auth()
            .signInWithCustomToken(custom_token)
            .then(() => {
              this.setupUser({ user });
              this.removeRegisterQueryParams();
              this.history.push('/');
              mixpanelTrack(MIXPANEL_EVENTS.FTF_USER_ACCOUNT_CREATED, {
                [MIXPANEL_KEYS.EXISTING_EMAIL]: 'No',
              });
              mixpanelTrack(MIXPANEL_EVENTS.TOS_SIGNED);
            })
            .catch(() => this.redirectToLoginPage(this.history));
        } else {
          this.redirectToLoginPage(this.history);
        }
      })
      .catch((error) => {
        const { status, data } = error;
        if (data && data?.channel) {
          this.history.push('/driver/existing-email', {
            isFleetTrackDuplicate: data?.channel === AppMode.FLEETTRACK,
            username: email,
          });
          mixpanelTrack(MIXPANEL_EVENTS.FTF_USER_ACCOUNT_CREATED, {
            [MIXPANEL_KEYS.EXISTING_EMAIL]: 'Yes',
          });
        } else if (status === 503) {
          this.history.push('/maintenance');
        } else {
          this.rootStore.snackbarStore.enqueueSnackbarStore('Error registering user.', {
            variant: 'error',
          });
        }
      });
  };

  setupFirebaseAuthListenerFleetTrack = () => {
    if (!this.authListener) {
      this.authListener = firebase.auth().onAuthStateChanged(
        (user) => {
          if (user) {
            ApiLayer.me()
              .then(async (foUser) => {
                if (foUser && foUser.user) {
                  this.setupUser(foUser);
                } else {
                  this.redirectToErrorPage();
                }
              })
              .catch(() => this.redirectToErrorPage());
          } else {
            this.authErrorHandler();
          }
        },
        () => this.authErrorHandler(),
      );
    }
  };

  checkFleetPulseLogin = async () => {
    let { user_id, token, activeUserId } = queryStringParser.parse(location.search);
    // Return all '+' signs after parsing query param
    token = token?.replaceAll(' ', '+');
    if (user_id && token) {
      this.handleFleetPulseLogin(user_id, token);
    } else if (activeUserId) {
      this.setActiveUserId(activeUserId);
      this.handleFleetPulseLogin(null, null);
    } else {
      this.setupFirebaseAuthListenerFleetPulse();
    }
  };

  checkPhillipsLogin = async () => {
    let { user_id, token, activeUserId } = queryStringParser.parse(location.search);
    // Return all '+' signs after parsing query param
    token = token?.replaceAll(' ', '+');
    if (user_id && token) {
      this.handleFleetPulseLogin(user_id, token);
    } else if (activeUserId) {
      this.setActiveUserId(activeUserId);
      this.handleFleetPulseLogin(null, null);
    } else {
      this.setupFirebaseAuthListenerFleetPulse();
    }
  };

  handleFleetPulseLogin = async (user_id: string | null, token: string | null) => {
    let formData = { email: user_id, token };
    if (this.activeUserId) {
      formData = {
        userId: this.activeUserId,
      };
    }
    ApiLayer.fleetPulseLogin(formData)
      .then((data) => {
        const { custom_token } = data;
        if (custom_token) {
          firebase
            .auth()
            .signInWithCustomToken(custom_token)
            .then(() => {
              this.setupFirebaseAuthListenerFleetPulse();
              this.removeRegisterQueryParams();
              if (!this.activeUserId) {
                this.history.push('/');
              }
            })
            .catch(() => {
              throw new Error(`Failed to log in FleetPulse user`);
            });
        } else {
          this.history.push('/driver/login');
          // Update the error implementation after confirmation from FleetPulse
          this.rootStore.snackbarStore.enqueueSnackbarStore('Error logging in user.', {
            variant: 'error',
          });
        }
      })
      .catch((error) => {
        this.rootStore.snackbarStore.enqueueSnackbarStore('Error logging in user.', {
          variant: 'error',
        });
      });
  };

  setupFirebaseAuthListenerFleetPulse = () => {
    if (!this.authListener) {
      this.authListener = firebase.auth().onAuthStateChanged(
        (user) => {
          if (user) {
            ApiLayer.me()
              .then(async (foUser) => {
                if (foUser && foUser.user) {
                  this.setupUser(foUser);
                } else {
                  this.redirectToErrorPage();
                }
              })
              .catch(() => this.redirectToErrorPage());
          } else {
            this.authErrorHandler();
          }
        },
        () => this.authErrorHandler(),
      );
    }
  };

  // Authentication/Registration Stage 4: Clean url pathname
  removeRegisterQueryParams = () => {
    // Auth params need to be removed from the url.
    const {
      location: { search, pathname },
    } = window;
    const parsedQueryParams = queryStringParser.parse(search);
    let cleanedSearch = '';
    // Pull default app-wide configured history and push
    this.history.push({
      pathname,
      search: cleanedSearch,
    });
  };

  redirectToLoginPage = (history: History) => {
    history.push('/driver/landing');
  };

  updateUserLocation = (truck) => {
    const {
      userStore: {
        setCurrentCoordinates,
        downloadCurrentCoordinatesAsync,
        setDownloadCurrentCoordinateFailure,
      },
    } = this.rootStore;
    var lastLocation = truck?.lastLocation;
    var position = lastLocation?.position;

    if (position?.lat && position?.lon && lastLocation?.source != 'web-ip') {
      var coordinates = { lat: position?.lat, lng: position?.lon };
      setCurrentCoordinates(coordinates);
      this.geocodeTruckLocationToMixpanel(coordinates);
      mixpanelUpdateUser(coordinates);
    } else {
      // Get coordinates from Web IP as backup
      downloadCurrentCoordinatesAsync().then((ip_coordinates) => {
        if (ip_coordinates?.lat && ip_coordinates?.lng)
          this.geocodeTruckLocationToMixpanel(ip_coordinates);
        setDownloadCurrentCoordinateFailure(null);
      });
    }
  };

  geocodeTruckLocationToMixpanel = (coordinates) => {
    try {
      if (coordinates && coordinates.lat && coordinates.lng) {
        var geoCoordinates = new google.maps.LatLng(coordinates.lat, coordinates.lng);

        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ location: geoCoordinates }, (geocodeResults) => {
          if (geocodeResults && geocodeResults.length > 0) {
            const address = geocodeResults[0].formatted_address;
            const currentLocationAddress = {
              [MIXPANEL_KEYS.CURRENT_LOCATION]: address,
            };
            const currentLocation = { ...currentLocationAddress };
            geocodeResults[0].address_components.forEach((addressComponent) => {
              if (addressComponent.types.includes('locality')) {
                currentLocation[MIXPANEL_KEYS.CITY] = addressComponent.long_name;
              }
              if (addressComponent.types.includes('administrative_area_level_1')) {
                currentLocation[MIXPANEL_KEYS.STATE] = addressComponent.long_name;
              }
              if (addressComponent.types.includes('country')) {
                currentLocation[MIXPANEL_KEYS.COUNTRY] = addressComponent.long_name;
              }
              if (addressComponent.types.includes('postal_code')) {
                currentLocation[MIXPANEL_KEYS.POSTAL_CODE] = addressComponent.long_name;
              }
            });
            mixpanelRegister(currentLocationAddress);
            mixpanelUpdateUser(currentLocation);
          }
        });
      } else {
        throw Error('Missing Truck Location coordinates');
      }
    } catch (e) {
      console.error('Failed to geocode coordinates to mixpanel: ', e);
    }
  };
}
