import Phillips from 'assets/images/logos/Phillips.svg';
import production from './production';
import staging from './staging';
import development from './development';
import test from './test';

const configVariants = {
  production,
  staging,
  development,
  test,
};
const config = {
  freshworksId: 43000003167,
  partner: {
    url: '', // UPDATE WHEN AVAILABLE
    name: 'Phillips Freight',
    partnerName: 'Phillips',
    logo: Phillips,
  },
  trailerSolution: true,
  ...configVariants[process.env.NODE_ENV || 'development'],
};

export default config;
