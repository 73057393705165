const primary = {
  light: '#8c9bb4',
  main: '#193769',
  dark: '#16315e',
  disabled: '#d1d7e1',
  contrastText: '#FFFFFF',
  medium: '#2f4b78',
};
const secondary = {
  light: '#bfd5b5',
  main: '#5f9747',
  dark: '#426931',
  disabled: '#dfeada',
  medium: '#6aa84f',
  contrastText: '#FFFFFF',
};
const palette = {
  primary,
  secondary,
  partnerCustomPrimary: primary,
  partnerCustomSecondary: secondary,
  background: {
    walkthroughPurple: '#BC0029', // Walkthrough bubble bg, etc
  },
  navy: '#193769', // Search LP form background, etc
};
export default palette;
